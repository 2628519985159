


.section{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.left{

}

.right{
  
}
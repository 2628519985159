

.h_note{
  width:40%;
}

@media screen and (max-width: 500px) {
  .h_note{
    width: 60%;
  }
}
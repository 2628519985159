

.section{
  display: flex;
  /* width: 60%; */
  justify-content: space-evenly;
  margin-top: 20px;
}
.gen3{
  margin-top: -80px;
}
@media screen and (max-width:1150px) {
  .section{
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }
  .gen3d_title{
    margin-bottom: 50px;
  }
}


@media screen and (max-width: 500px ) {
  .gen3{
    margin-top: 0px;
    width: 85%;
  }
}



.gold{
  width: 100%;
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.main{
  max-width: 1600px;
  margin: 0 auto;
}

@media screen and (max-width: 920px) {
  .gold img{
    width: 40%;
  }
}
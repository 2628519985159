.main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: auto;
}

.left img {
  width: 80%;
}

.bottom_section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -70px;
}
.mint {
  margin-top: 30px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.small-gap {
  gap: 10px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.text-white {
  color: white;
}

@media screen and (max-width: 768px) {
  .section {
    flex-direction: column;
    width: 100%;
  }
  .left img {
    width: 100%;
  }
}

@media screen and (max-width: 1470px) {
  .bottom_section img {
    width: 25%;
  }
}

@media screen and (max-width: 730px) {
  .mint {
    margin-bottom: 100px !important;
  }
}
